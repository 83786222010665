import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useIsMobile, useTranslate } from "../../services/hooks";
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { get, post } from "../../services/api";
import { Header } from "../../components/Header/Header";
import Sidebar from "../../UI/Sidebar/Sidebar";
import BasicData from "./CompanyInfoPages/BasicData/BasicData";
import SpiderWeb from "./CompanyInfoPages/SpiderWeb/SpiderWeb";
import { toast } from "react-toastify";

const CompanyProfile = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const { id } = useParams();
  const [code, setCode] = useState();
  const {
    data: company_translate_data,
    isPending,
    isSuccess,
  } = useTranslate("company-profile", "COMPANY_PROFILE");
  const { data: menu_data } = useTranslate("menu", "MENU");

  const {
    data: company_data,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["company", id],
    queryFn: async () => {
      return await get(
        `/portal/companies/search/company/${id}`,
        "PORTAL_COMPANY_BASIC_DETAILS",
      )
        .then((res) => {
          setCode(res?.code ?? res?.status);
          if (res?.code === 200) {
            return res?.payload;
          } else {
            toast.error(res?.data?.message, {
              position: "top-center",
            });
            navigate("/dashboard");
            return {};
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    refetchOnWindowFocus: false,
  });

  const {} = useQuery({
    queryKey: ["limits"],
    queryFn: async () => {
      return await post(
        `/portal/companies/view`,
        {
          company_id: id,
        },
        "PORTAL_COMPANY_BASIC_DETAILS",
      );
    },
  });

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  const is_mobile = useIsMobile();
  const [open, setOpen] = useState(!is_mobile);

  let element = null;

  switch (page) {
    case "basic-data":
      element = (
        <BasicData
          open={open}
          company_translate_data={company_translate_data}
          isPending={isPending}
          isLoading={isLoading}
          code={code}
          company_data={company_data}
        />
      );
      break;
    case "spider-web":
      element = (
        <SpiderWeb
          open={open}
          company_translate_data={company_translate_data}
          company_data={company_data}
        />
      );
      break;
    case "history":
      element = (
        <SpiderWeb
          open={open}
          company_translate_data={company_translate_data}
          company_data={company_data}
        />
      );
      break;
  }

  return (
    <>
      <Header />
      <Sidebar
        setOpen={setOpen}
        open={open}
        company_data={company_data}
        menu_data={menu_data}
      />
      {element}
    </>
  );
};

export default CompanyProfile;
