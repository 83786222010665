import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { get } from "../../../services/api";
import { currencyFormat } from "../../../utils/functions";

const CompanyInfoHeader = ({
  company_translate_data: translations,
  company_data: data,
  code,
  isLoading,
}) => {
  return (
    <div className={`@container`}>
      {isLoading ? (
        <div
          className={`min-h-[5rem] max-h-[7rem] bg-slate-300 w-full h-full animate-pulse rounded-lg mt-7`}
        ></div>
      ) : (
        <div
          className={`flex ${
            code === 403 && "border"
          } flex-col @[78.125rem]:flex-row @[93.438rem]:flex-row items-center bg-white shadow-xl rounded-lg @[1138px]:gap-5 @[1150px]:justify-between justify-between px-[1rem] max-sm:px-[1rem] mt-7 py-[2rem]`}
        >
          {code === 403 ? (
            <p>Ovu sekciju ne možete videti.</p>
          ) : (
            <>
              <div className={`flex flex-col gap-5`}>
                {data?.logo ? (
                  <a href={`https://${data?.web}`} target={`_blank`}>
                    <img src={data?.logo} alt="Logo" />
                  </a>
                ) : data?.naziv_skraceni ? (
                  data?.web ? (
                    <a
                      className={`p-5 w-full bg-[#2b343b] rounded-lg transition-all duration-300 hover:bg-[#ed545c] text-white font-bold text-[1.025rem]`}
                      href={data?.web ? `https://${data?.web}` : "#"}
                      target={`_blank`}
                    >
                      {data?.naziv_skraceni}
                    </a>
                  ) : (
                    <span
                      className={`p-5 w-full bg-[#2b343b] rounded-lg transition-all duration-300 hover:bg-[#ed545c] text-white font-bold text-[1.025rem]`}
                    >
                      {data?.naziv_skraceni}
                    </span>
                  )
                ) : null}

                {/*<div className={`flex flex-col items-center`}>*/}
                {/*  <div className={`flex items-center gap-9`}>*/}
                {/*    <div className={`flex items-center gap-2`}>*/}
                {/*      <p className={`text-sm hover:underline cursor-pointer`}>*/}
                {/*        Posetite sajt*/}
                {/*      </p>*/}
                {/*      <img src={Web} alt="Web" width={`18`} className={``} />*/}
                {/*    </div>*/}
                {/*    <div className={`flex items-center gap-2`}>*/}
                {/*      <p className={`text-sm hover:underline cursor-pointer`}>*/}
                {/*        Kontakt telefon*/}
                {/*      </p>*/}
                {/*      <img src={Phone} alt="Web" width={`18`} className={``} />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <h1*/}
                {/*    className={`text-sm font-semibold text-center self-center mt-3 hover:underline cursor-pointer`}*/}
                {/*  >*/}
                {/*    Zahtevajte kontakt određene osobe*/}
                {/*  </h1>*/}
                {/*</div>*/}
              </div>
              <div
                className={`grid grid-cols-2 shrink-0  self-stretch flex-1 mt-5 @[93.438rem]:mt-0 w-full @[93.438rem]:max-w-[65%]`}
              >
                <div
                  className={`col-span-1  pl-5 rounded-l-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["full_name"]?.["label"] ?? "Pun naziv"}
                    :&nbsp;
                    <span className={`font-normal max-sm:hidden`}>
                      {data?.naziv_pun}
                    </span>{" "}
                  </h1>{" "}
                  <span className={`sm:hidden font-normal`}>
                    {data?.naziv_pun}
                  </span>{" "}
                </div>
                <div
                  className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["status"]?.["label"] ?? "Status"}
                    :&nbsp;
                    <span
                      className={`max-sm:hidden text-green-500 font-medium`}
                    >
                      {data?.status}
                    </span>
                  </h1>
                  <span className={`sm:hidden text-green-500 font-medium`}>
                    {data?.status}
                  </span>
                </div>
                <div
                  className={`col-span-1  pl-5 rounded-l-lg flex flex-col items-start justify-center `}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["activity"]?.["label"] ?? "Delatnost"}
                    :&nbsp;
                    <span className={`font-normal max-sm:hidden`}>
                      {data?.delatnost}
                    </span>{" "}
                  </h1>{" "}
                  <span className={`font-normal sm:hidden`}>
                    {data?.delatnost}
                  </span>{" "}
                </div>
                <div
                  className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center `}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["mb"]?.["label"] ?? "MB"}
                    :{" "}
                    <span className={`font-normal max-sm:hidden`}>
                      {data?.maticni_broj}
                    </span>{" "}
                  </h1>
                  <span className={`font-normal sm:hidden`}>
                    {data?.maticni_broj}
                  </span>
                </div>
                <div
                  className={`col-span-1  pl-5 rounded-l-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["address"]?.["label"] ?? "Adresa"}
                    :&nbsp;
                    <a
                      target={`_blank`}
                      href={`https://www.google.com/maps/search/${
                        data?.adresa ? data?.adresa : ""
                      } ${data?.mesto ? data?.mesto : ""}`}
                      className={`font-normal max-sm:hidden hover:underline`}
                    >
                      {data?.adresa}
                    </a>{" "}
                  </h1>
                  <a
                    target={`_blank`}
                    href={`https://www.google.com/maps/search/${
                      data?.adresa ? data?.adresa : ""
                    } ${data?.mesto ? data?.mesto : ""}`}
                    className={`font-normal sm:hidden hover:underline`}
                  >
                    {data?.adresa}
                  </a>
                </div>
                <div
                  className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["pib"]?.["label"] ?? "PIB"}
                    :{" "}
                    <span className={`font-normal max-sm:hidden`}>
                      {data?.pib}
                    </span>{" "}
                  </h1>
                  <span className={`font-normal sm:hidden`}>{data?.pib}</span>
                </div>
                <div
                  className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center`}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["city"]?.["label"] ?? "Grad"}
                    :{" "}
                    <a
                      target={`_blank`}
                      href={`https://www.google.com/maps/search/${
                        data?.adresa ? data?.adresa : ""
                      } ${data?.mesto ? data?.mesto : ""}`}
                      className={`font-normal max-sm:hidden hover:underline`}
                    >
                      {data?.mesto}
                    </a>{" "}
                  </h1>
                  <a
                    target={`_blank`}
                    href={`https://www.google.com/maps/search/${
                      data?.adresa ? data?.adresa : ""
                    } ${data?.mesto ? data?.mesto : ""}`}
                    className={`font-normal sm:hidden hover:underline`}
                  >
                    {data?.mesto}
                  </a>
                </div>
                <div
                  className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center`}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["pdv"]?.["label"] ?? "PDV"}
                    :{" "}
                    <span className={`font-normal max-sm:hidden`}>
                      {data?.pdv}
                    </span>{" "}
                  </h1>
                  <span className={`font-normal sm:hidden`}>{data?.pdv}</span>
                </div>
                <div
                  className={`col-span-1  pl-5 rounded-l-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["phone"]?.["label"] ?? "Telefon"}
                    :&nbsp;
                    <a
                      href={`tel:${data?.telefon}`}
                      className={`font-normal max-sm:hidden hover:underline`}
                    >
                      {data?.telefon}
                    </a>{" "}
                  </h1>
                  <a
                    href={`tel:${data?.telefon}`}
                    className={`font-normal sm:hidden hover:underline`}
                  >
                    {data?.telefon}
                  </a>
                </div>
                <div
                  className={`col-span-1  pl-5 rounded-r-lg flex flex-col items-start justify-center bg-[#f4f7fa]`}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["founding_date"]?.["label"] ?? "Datum osnivanja"}
                    :&nbsp;
                    <span className={`font-normal max-sm:hidden`}>
                      {data?.datum_osnivanja}
                    </span>{" "}
                  </h1>{" "}
                  <span className={`font-normal sm:hidden`}>
                    {data?.datum_osnivanja}
                  </span>
                </div>
                <div
                  className={`col-span-1  pl-5 rounded-l-lg flex flex-col items-start justify-center `}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["email"]?.["label"] ?? "Email"}
                    :&nbsp;
                    <a
                      href={`mailto:${data?.email}`}
                      className={`font-normal max-sm:hidden hover:underline`}
                    >
                      {data?.email}
                    </a>{" "}
                  </h1>
                  <a
                    href={`mailto:${data?.email}`}
                    className={`font-normal sm:hidden hover:underline`}
                  >
                    {data?.email}
                  </a>
                </div>
                <div
                  className={`col-span-1 gap-10  pl-5 rounded-r-lg flex max-sm:gap-5 items-center justify-start`}
                >
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["blockages"]?.["label"] ?? "Blokade"}
                    :&nbsp;
                    <span className={`font-normal`}>{data?.blokade}</span>{" "}
                  </h1>
                  <h1 className={`text-[0.95rem] font-semibold`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["bills_of_exchange"]?.["label"] ?? "Menice"}
                    :&nbsp;
                    <span className={`font-normal`}>{data?.menice}</span>{" "}
                  </h1>
                </div>
              </div>
              <div
                className={`flex flex-col self-stretch gap-2.5 h-full mt-5 @[93.438rem]:mt-0 w-full @[78rem]:w-[160px] @[93.438rem]:w-[160px]`}
              >
                <div
                  className={`flex flex-col gap-1 items-center justify-center`}
                >
                  <div
                    className={`w-full py-1 flex flex-col items-center text-center rounded-lg`}
                    style={{
                      backgroundColor: `${data?.color}`,
                    }}
                  >
                    <h1 className={`text-white font-semibold`}>
                      {data?.scoring_ocena}
                    </h1>
                  </div>
                  <p className={`text-sm font-medium`}>Scoring</p>
                </div>
                <div
                  className={`flex flex-col  gap-1 items-center justify-center`}
                >
                  <div
                    className={`w-full flex py-1 flex-col items-center text-center rounded-lg bg-[#2b343b]`}
                  >
                    <h1 className={`text-white font-semibold`}>
                      {currencyFormat(data?.kreditni_limit)}
                    </h1>
                  </div>
                  <p className={`text-sm font-medium`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["credit_limit"]?.["label"] ?? "Kreditni limit"}
                  </p>
                </div>
                <div
                  className={`flex flex-col gap-1 items-center justify-center`}
                >
                  <div
                    className={`w-full flex py-1 flex-col items-center text-center rounded-lg bg-[#2b343b]`}
                  >
                    <h1 className={`text-white font-semibold`}>
                      {data?.monitoring}
                    </h1>
                  </div>
                  <p className={`text-sm font-medium`}>
                    {translations?.["company-profile"]?.["basic-data"]?.[
                      "header"
                    ]?.["monitoring"]?.["label"] ?? "Monitoring"}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyInfoHeader;
