import { Header } from "../../components/Header/Header";
import { SidebarProfile as Sidebar } from "../../UI/SidebarProfile/sidebar-profile";
import { useIsMobile, useTranslate } from "../../services/hooks";
import { useContext, useEffect, useState } from "react";
import { Section } from "../../components/shared/section";
import { useMutation, useQuery } from "@tanstack/react-query";
import { deleteMethod, get, post } from "../../services/api";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { userContext } from "../../services/userContext";
import Cookies from "js-cookie";
import { icons } from "../../lib/icons";

export const Account = () => {
  const is_mobile = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(
    searchParams?.get("tab") ?? "basic",
  );
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(!is_mobile);

  const { lang, setLang } = useContext(userContext);

  const onParamsSearchChange = (value) => {
    Cookies.set("selected_lang", value);
    setLang(value);
  };

  const { data: countries_ddl, refetch: refetchCountries } = useQuery({
    queryKey: ["ddl_countries"],
    queryFn: async () => {
      return await get(
        `/portal/profile/configs/ddl/countries?portal_filters=true`,
      ).then((res) => {
        if (res?.payload) {
          return res?.payload;
        } else {
          return [];
        }
      });
    },
  });
  const { data, refetch } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      return await get(`/portal/profile/configs`)?.then((res) => {
        if (res?.payload) {
          refetchCountries();
          refetchLang();
          setSelected({
            country: res?.payload?.country,
            language: res?.payload?.lang,
          });
          return res.payload;
        } else {
          return {};
        }
      });
    },
  });

  const {
    data: translate_data,
    isPending: is_pending,
    isSuccess,
  } = useTranslate("header", "HEADER");

  const { data: languages_ddl, refetch: refetchLang } = useQuery({
    queryKey: ["ddl_languages"],
    queryFn: async () => {
      return await get(`/portal/profile/configs/ddl/languages`).then((res) => {
        if (res?.payload) {
          return res?.payload;
        } else {
          return [];
        }
      });
    },
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ["save"],
    mutationFn: async () => {
      return await post(
        `/portal/profile/configs`,
        {
          id: data?.id ?? "",
          country: selected?.country ?? data?.country,
          lang: selected?.language ?? data?.lang,
        },
        "USERS",
      )?.then((res) => {
        if (res?.code === 200) {
          onParamsSearchChange(selected?.language ?? data?.lang);
          refetch();
          toast.success("Uspešno sačuvano!", {
            position: "top-center",
          });
        } else {
          toast.error("Greška prilikom čuvanja!", {
            position: "top-center",
          });
        }
      });
    },
  });

  const handleChange = (value, name) => {
    setSelected((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setSelectedTab(searchParams?.get("tab") ?? "basic");
  }, [searchParams]);

  let element = <></>;

  switch (selectedTab) {
    case "basic":
      element = (
        <Basic
          active_tab={selectedTab}
          selected={selected}
          languages_ddl={languages_ddl}
          countries_ddl={countries_ddl}
          handleChange={handleChange}
          data={data}
          mutate={mutate}
          isPending={isPending}
        />
      );
      break;
    case "limits":
      element = <Limits />;
      break;
    case "companies":
      element = <CompaniesFollowingList />;
      break;
  }

  return (
    <>
      <Header />
      <Sidebar
        setOpen={setOpen}
        open={open}
        data={data}
        translate_data={translate_data}
      />
      <div
        className={`${
          open ? `pl-[23rem] 3xl:pl-[25rem]` : ` max-md:px-[1rem] md:pl-[3rem]`
        } mx-auto pr-[3rem] transition-all duration-500 `}
      >
        {element}
      </div>
    </>
  );
};

const Basic = ({
  selected,
  countries_ddl,
  languages_ddl,
  handleChange,
  data,
  mutate,
  isPending,
}) => {
  return (
    <Section className={`grid grid-cols-3 gap-5`}>
      <div className={`col-span-full`}>
        <h2 className={`mb-5`}>Osnovne informacije o korisniku</h2>
      </div>
      <div className={`col-span-1`}>
        <FormControl fullWidth>
          <InputLabel htmlFor="country">Država</InputLabel>
          <Select
            fullWidth
            value={selected?.country ?? data?.country}
            id={"country"}
            onChange={({ target: { value } }) => {
              handleChange(value, "country");
            }}
            label={"Država"}
            placeholder={"Država"}
            name={"country"}
          >
            {(countries_ddl ?? []).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={`col-span-1`}>
        <FormControl fullWidth>
          <InputLabel htmlFor="country">Jezik</InputLabel>
          <Select
            fullWidth
            value={selected?.language ?? data?.lang}
            id={"language"}
            onChange={({ target: { value } }) => {
              handleChange(value, "language");
            }}
            label={"Jezik"}
            placeholder={"Jezik"}
            name={"language"}
          >
            {(languages_ddl ?? []).map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={`col-span-full flex`}>
        <Button
          disabled={isPending}
          onClick={() => {
            mutate();
          }}
          color={"success"}
          variant={"contained"}
          sx={{ marginLeft: "auto" }}
        >
          Sačuvaj
        </Button>
      </div>
    </Section>
  );
};

const Limits = () => {
  const { data: limits = [] } = useQuery({
    queryKey: ["limits_profile"],
    queryFn: async () => {
      return await get(`/portal/profile/limits`)?.then((res) => {
        if (res?.payload) {
          return res.payload;
        }
      });
    },
  });

  const renderTable = (title, limit) => (
    <TableContainer
      component={Paper}
      sx={{
        marginBottom: 4,
        borderRadius: 2,
        boxShadow: 3,
        overflow: "hidden",
      }}
    >
      <Box sx={{ backgroundColor: "#ed545c", color: "white", padding: 1 }}>
        <p>{title}</p>
      </Box>
      <Table>
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
              Iskorišćeno
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
              Ukupan limit
            </TableCell>
            <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
              Preostalo
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            sx={{ "&:nth-of-type(even)": { backgroundColor: "#fafafa" } }}
          >
            <TableCell sx={{ width: "33.33%" }}>
              {new Intl.NumberFormat("sr-RS").format(limit?.used)}
            </TableCell>
            <TableCell sx={{ width: "33.33%" }}>
              {new Intl.NumberFormat("sr-RS").format(limit?.limit)}
            </TableCell>
            <TableCell sx={{ width: "33.33%" }}>
              {new Intl.NumberFormat("sr-RS").format(limit?.remaining)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Section>
      {(limits ?? [])?.map((limit) => renderTable(limit?.table_name, limit))}
    </Section>
  );
};

const CompaniesFollowingList = () => {
  const { data: list = [], refetch } = useQuery({
    queryKey: ["companies_following"],
    queryFn: async () => {
      return await post(`/portal/profile/companies/list`, {
        search: "",
        limit: "",
        page: 1,
        filters: [],
        sort: [],
      })?.then((res) => {
        if (res?.payload?.items?.length > 0) {
          return res?.payload?.items;
        } else {
          return [];
        }
      });
    },
  });

  // const handleMessage = useMutation({
  //   mutationKey: ["message"],
  //   mutationFn: async ({ id }) => {
  //     return await get(`/portal/profile/companies/message/${id}`)?.then(
  //       (res) => {},
  //     );
  //   },
  // });

  const { mutate: unfollow, isSuccess } = useMutation({
    mutationKey: ["message"],
    mutationFn: async ({ id }) => {
      return await deleteMethod(
        `/portal/profile/companies/confirm/${id}`,
      )?.then((res) => {
        if (res?.code === 200) {
          toast.success(res?.payload?.message, {
            position: "top-center",
          });
        } else {
          toast.error(res?.data?.message, {
            position: "top-center",
          });
        }
      });
    },
  });
  useEffect(() => {
    isSuccess && refetch();
  }, [isSuccess]);

  const navigate = useNavigate();

  return (
    <Section>
      <TableContainer
        component={Paper}
        sx={{
          marginBottom: 4,
          borderRadius: 2,
          boxShadow: 3,
          overflow: "hidden",
        }}
      >
        <Box sx={{ backgroundColor: "#ed545c", color: "white", padding: 1 }}>
          <p>Firme koje pratim</p>
        </Box>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
                Naziv
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
                PIB
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
                Matični broj
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: "33.33%" }}>
                Akcije
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(list ?? [])?.map((item) => {
              const { naziv, pib, firma_id, maticni_broj } = item;
              return (
                <TableRow className={`hover:bg-[#f7f7f7]`}>
                  <TableCell
                    className={`cursor-pointer`}
                    onClick={() => {
                      navigate(`/company-profile/basic-data/${firma_id}`);
                    }}
                    sx={{ width: "25%" }}
                  >
                    {naziv}
                  </TableCell>
                  <TableCell
                    className={`cursor-pointer`}
                    onClick={() => {
                      navigate(`/company-profile/basic-data/${firma_id}`);
                    }}
                    sx={{ width: "25%" }}
                  >
                    {pib}
                  </TableCell>
                  <TableCell
                    className={`cursor-pointer`}
                    onClick={() => {
                      navigate(`/company-profile/basic-data/${firma_id}`);
                    }}
                    sx={{ width: "25%" }}
                  >
                    {maticni_broj}
                  </TableCell>
                  <TableCell sx={{ width: "25%" }}>
                    <Button
                      onClick={() => {
                        unfollow({ id: firma_id });
                      }}
                      color={"error"}
                      variant={"contained"}
                    >
                      {icons.unsave}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
};
