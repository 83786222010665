import CompanyInfoHeader from "../../../../components/CompanyInfoComponents/CompanyInfoHeader/CompanyInfoHeader";
import CompanyDiagram from "../../../../components/CompanyInfoComponents/CompanyDiagram/CompanyDiagram";
import CompanyChanges from "../../../../components/CompanyInfoComponents/CompanyChanges/CompanyChanges";
import CompanyInformations from "../../../../components/CompanyInfoComponents/CompanyInformations/CompanyInformations";

const BasicData = ({
  open,
  company_translate_data,
  isPending,
  company_data,
  code,
  isLoading,
}) => {
  return (
    <div
      className={`${
        open ? `pl-[23rem] 3xl:pl-[25rem]` : ` max-md:px-[1rem] md:pl-[3rem]`
      } mx-auto pr-[3rem] transition-all duration-500 `}
    >
      <CompanyInfoHeader
        company_translate_data={company_translate_data}
        company_data={company_data}
        code={code}
        isLoading={isLoading}
      />
      <CompanyDiagram company_translate_data={company_translate_data} />
      {/*<CompanyChanges />*/}
      <CompanyInformations company_translate_data={company_translate_data} />
    </div>
  );
};

export default BasicData;
